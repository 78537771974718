import _ from "underscore";
import Backbone from "backbone";

import {appConfiguration, RouterBase} from "@riag-libs/drtax.client-common";

// MiscellaneousRouter
export default RouterBase.extend({

  routes: {
    'impressum': 'showImpressum'
  },

  isAuthorizedRoute: function (route) {
    return false;
  },

  showImpressum: function () {
    const self = this;
    var locale = this.sessionModel.get('locale');
    requirejs(['text!templates/'+locale+'/impressum.html'],
      function (pageTemplate) {
        const view = new (Backbone.View.extend({
          template: _.template(pageTemplate),

          render: function () {
            const skeleton = Skeleton.elements.Button({
              colorType: "primary",
              formType: "mainButton",
              className: "l-mt-3",
              href: self.getPreviousRoute() || '#'
            }, $.i18n('commons-controls.back'));
            this.$el.html(this.template({
              version: appConfiguration.versions.release || '',
              button: skeleton.toString(),
            }));
            return this;
          },
        }))({});
        self.renderAndSetView(view);
      });
  },
});
