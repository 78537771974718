import {TransferChooserBaseView, ImportController, ImportChooserView} from "@riag-libs/drtax.client-common";

const TransferChooserViewBl = TransferChooserBaseView.extend({

})

const ImportChooserViewBl = ImportChooserView.extend({

  createUploadControl: function() {
    var thumb = Skeleton.elements.Thumbnail({
      title: $.i18n("import-view.import-choose.buttonLabel"),
      icon: {
        props: {
          name: "plus-4",
          size: "large",
        },
      },
      upload: {props: {name: "data", accept: ".d21,application/zip"}}
    });
    return thumb;
  },
})

class ImportControllerBl extends ImportController {

  createImportChooserViewInstance(args) {
    args.model.set('iconName', "bl-logo")
    args.transferGroupId = "ch.bl.2022"
    return new ImportChooserViewBl(args);
  }

  createTransferChooserViewInstance(args) {
    args.model.set('iconName', "bl-logo")
    args.transferGroupId = this._getTransferGroupId();
    return new TransferChooserViewBl(args)
  }

};

export default ImportControllerBl;