import numeral from 'numeral';
import marked from "marked";

import App from './modules/app';
import {appConfiguration, registerNumeralLocales} from '@riag-libs/drtax.client-common';

registerNumeralLocales(numeral);
numeral.locale('de-CH'); // this locale supports swiss number formatting, registered under 'fake' locale

window.numeral = numeral; // for using numeral in underscore templates
window.marked = marked;   // for using marked in underscore templates

appConfiguration.versions.release =  window.versions.client;
appConfiguration.taxYear = 2022;
appConfiguration.urlArgs = window.urlArgs ? '?' + window.urlArgs : '';
appConfiguration.supportedLocales = ['de'];
appConfiguration.defaultGroupId = 'ch.bl';
appConfiguration.supportedGroupIds = ['ch.np.bl.2022']
appConfiguration.externalHelpUrl = "http://bl-support.etax.ch";
appConfiguration.deleteOnBackFromOnboarding = true;
appConfiguration.blChannel = "bl-channel"
appConfiguration.timeout = 180*1000 // tax statement and previous year import can be very slow

const next = function() {
  const app = new App();
  app.initialize();
  app.start();
}

// load additional config if available
$.ajax({url: 'lib/app_config.json' + appConfiguration.urlArgs, type: 'GET'}).then(function(config){
  // allow overriding ALL parameters
  for (var key in config) {
    appConfiguration[key] = config[key];
  }
  next();
}, function(err){
  console.warn(err);
  next();
});

