import {DocumentChooserController, DocumentChooserView, DeleteDocumentView, MenuFactory, appConfiguration} from "@riag-libs/drtax.client-common";

class DocumentChooserViewBL extends DocumentChooserView {

  initialize(options) {
    super.initialize(options);
    this.sessionModel = options.sessionModel;
  }

  getHeaderMenuEntries() {
    const entries = super.getHeaderMenuEntries();
    entries.splice(1, 0, MenuFactory.createImprintEntry());
    return entries;
  }

  createRowMenuEntries(taxData, document, editHref, submissionHref) {
    // ETAXBL-620 remove default export action (for chosen ones) and add custom one, for everybody
    taxData.exportAction = false;
    const menuEntries = DocumentChooserView.prototype.createRowMenuEntries.call(this, taxData, document, editHref, submissionHref)
    menuEntries.push({
      children: $.i18n("document-chooser.action.export"),
      props: {
        icon: {
          props: {
            name: "square-download",
          },
        },
        href: appConfiguration.servicesBaseUrl + appConfiguration.blChannel + '/export/' + document.id
      },
    })
    return menuEntries;
  }

}

class DocumentChooserControllerBL extends DocumentChooserController {

  createDocumentChooserView(options) {
    options.centralAddButton = true;
    options.centralAddButtonAlwaysVisible = true;
    options.centralContextMenu = true;
    options.sessionModel = this.sessionModel;
    return new DocumentChooserViewBL(options);
  }

}

export default DocumentChooserControllerBL;
