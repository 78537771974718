import {CredsStepView} from "@riag-libs/drtax.client-common";
import _ from "underscore";
import whereismycode from './whereismycode.html'

const CredStepViewBL = CredsStepView.extend({
  whereismycode_template: _.template(whereismycode),

  createWhereismycode: function() {
    return this.whereismycode_template({
      imageUrl: 'img/help/whereismycode_de.png'
    })
  }
})
export default CredStepViewBL
