import '@riag-libs/drtax.client-common/lib/css/drtax.css'

import {
	appConfiguration,
	DocumentService,
	DocumentChooserRouter,
	DocumentRouter,
	TransferService,
	RouterUtils,
	ImportRouter,
	KeycloakAppBase
} from '@riag-libs/drtax.client-common';
import DocumentController from './document-controller-bl';
import DocumentChooserController from './document-chooser-controller-bl';
import MiscellaneousRouter from './miscellaneous-router-bl';
import ImportControllerBl from './import-controller-bl';

const getFooterText = function () {
	var versionNum = appConfiguration.versions.release;
	if (!versionNum) {
		console.warn('Release version undefined!');
		return '';
	}
	return $.i18n("footer.versionText", versionNum);
};

class App extends KeycloakAppBase {


	initialize() {
		this.defaultGroupId = appConfiguration.defaultGroupId;
		KeycloakAppBase.prototype.initialize.call(this);

		this.transferService = new TransferService(this.requestHandler);
		const isAuthenticated = function () {
			return true;
		}.bind(this);
		new DocumentChooserRouter({
			$el: this.$root,
			isAuthenticated: isAuthenticated,
      redirectToDocumentList: true,
			controller: this.controllers.documentChooser
		})
		new DocumentRouter({
			$el: this.$root,
			isAuthenticated: isAuthenticated,
			controller: this.controllers.document
		})
		new ImportRouter({
			$el: this.$root,
			isAuthenticated: isAuthenticated,
			controller: this.createImportControllerBl(),
		})
		this.routers.push(new MiscellaneousRouter({$el: this.$root, isAuthenticated: isAuthenticated, sessionModel: this.models.session}));
		RouterUtils.registerRouterDefaultRedirect('');
	};

	createDocumentController() {
		return new DocumentController({
			documentService: this.documentService,
			sessionModel: this.models.session,
			defaultGroupId: this.defaultGroupId,
      withOnboarding: false,
			declarationType: appConfiguration.declarationType,
			cockpitService: this.cockpitService,
			pdfscannerService: this.pdfscannerService
		});
	}

	createDocumentChooserController() {
		return new DocumentChooserController({
			documentService: this.documentService,
			transferService: this.transferService,
			sessionModel: this.models.session,
			groupIds: appConfiguration.supportedGroupIds,
			declarationType: appConfiguration.declarationType,
			iconName: "bl-logo",
			footerText: getFooterText,
      versionDetails: appConfiguration.getVersionDetails()
		});
	}

	createImportControllerBl() {
		return new ImportControllerBl({
			documentService: this.documentService,
			transferService: this.transferService,
			sessionModel: this.models.session,
			createLangMenu: false,
			uploadUrl: appConfiguration.servicesBaseUrl + appConfiguration.blChannel + "/import"
		})
	}

	afterKeycloakSuccess() {
		var loadParams = {}
		var locale = this.models.session.get('locale');
		loadParams[locale] = '/services/i18n/client/'+locale+'/client_bl'+appConfiguration.urlArgs;
		$.i18n().load(loadParams).then(()=>{
			Backbone.history.start();
		},()=>{
		  // failure
		  console.error("Failed to load customized i18n resource layear");
		  // but the app can start anyway
		  Backbone.history.start();
		})
	}

}
export default App;
