import _ from 'underscore';
import {
  DocumentEditView,
  appConfiguration
} from '@riag-libs/drtax.client-common';
import {SubmissionStateView} from './sub-view';

class DocumentEditViewBL extends DocumentEditView {

  initialize(args) {
    super.initialize(args);

    _.extend(this.subviewCreators, {

      "submission-state": function () {
        const subviewProps = this.getSubviewProps("submission-state");
        const subtype = subviewProps.subtype ? subviewProps.subtype : ''
        const type = subviewProps.type ? subviewProps.type.toLowerCase() : 'np';
        const values = _.map(['pidNumber', 'taxYear'], function (id) {
          return this.dataModel.getById(id) ? this.dataModel.getById(id).get('updatedValue') : '';
        }, this);
        const options = {
          submissions: this.templateData.submissionList,
          pidNumber: values[0],
          taxYear: values[1],
          locale: this.locale,
          type: type,
          subtype: subtype
        };
        console.log(options);
        return new SubmissionStateView(options);
      },

    })
  }

  createCompletenessCheckSubview(args) {
    const subview = super.createCompletenessCheckSubview(args);

    subview.checkboxClassname = 't-bold l-start';
    subview.getConfirmationTextChildren = function() {
      let htmlStr = '<ul class="l-ml-2">';
      const suffixes = ['0','1','2'];
      _.each(suffixes, function(suffix){
        htmlStr += '<li>' + $.i18n("completeness-check-view.confirmation_text." + suffix) + '</li>';
      });
      htmlStr += '</ul>';
      return $(htmlStr);
    };

    return subview;
  }

  getFooterModel(level, href2parent) {
    const model = super.getFooterModel.apply(this, arguments);
    if(model && model.get('navigationButton') && model.get('navigationButton').get('actionId') === "printPreview") {
      model.get('navigationButton').set('iconName', '');
    }
    return model;
  }
}

export default DocumentEditViewBL;
