import {appConfiguration, DocumentController} from "@riag-libs/drtax.client-common";
import DocumentEditViewBL from "./document-edit-view-bl";
import CreateNewController from "./createnew/create-new-controller-bl";

class DocumentControllerBL extends DocumentController {

  createDocumentEditViewInstance(options) {
    return new DocumentEditViewBL(options);
  }

  createCreateNewDocView(router, groupId) {
    const self = this;
    const resolvedGroupId = groupId ? groupId : appConfiguration.defaultGroupId;

    return new Promise(
      function (resolve, reject) {
        const createNewController = new CreateNewController({
          documentService: self.documentService,
          sessionModel: self.sessionModel,
          groupId: resolvedGroupId
        })
        createNewController.initialize()
        const view = createNewController.mainView
        resolve(view)
      }
    )
  }

}

export default DocumentControllerBL
