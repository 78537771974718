import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import fecha from 'fecha/fecha';

const taxCalculationArtifactId = 'typeSubmissionTaxCalculationOutput'

const SubmissionStateView = Backbone.View.extend({
  className: 'l-container l-left',

  pidNumber: '',
  municipality: '',
  submissions: undefined,
  subtype: '',

  initialize: function (args) {
    _.extend(this, _.pick(args, 'submissions', 'pidNumber', 'locale', 'taxYear', 'type', 'subtype'));
  },

  render: function() {
    const submissions = _.where(this.submissions, {status: true});
    const submission = _.first(submissions);
    if (!submission)
      return this;

    const isOfficial = submission.officialPrint;
    const expiryDate = submission.openUntil ? new Date(submission.openUntil) : null;
    const declarationThumbnail = 'declaration' + (this.type == 'np' ? '' : '-jp') + this.subtype + '.png';
    const receiptThumbnail = 'receipt' + (this.type == 'np' ? '' : '-jp') + this.subtype + '.png';
    const hasTaxCalc = submission.metaData.Artifacts && submission.metaData.Artifacts[taxCalculationArtifactId]
    const thuDeclaration = Skeleton.elements.Thumbnail({
      className: 'l-mb-025 l-mr-05',
      title: $.i18n(isOfficial ? "submission-view.declaration-official.title" : "submission-view.declaration.title"),
      type: "success",
      filename: $.i18n("submission-view.declaration.filename"),
      href: "/services/datamodel/submittedDeclaration/" + submission.id,
      background: "img/"+this.taxYear+"/"+declarationThumbnail,
      "rg-container-id": "thumbnail-declaration"
    });
    const thuReceipt = Skeleton.elements.Thumbnail({
      className: 'l-mb-025 l-mr-05',
      title: $.i18n("submission-view.receipt.title"),
      type: "success",
      filename: $.i18n("submission-view.receipt.filename"),
      href: "/services/datamodel/submissionReceipt/" + submission.id,
      background: "img/"+this.taxYear+"/"+receiptThumbnail,
      "rg-container-id": "thumbnail-receipt"
    });
    const thuAttachments = Skeleton.elements.Thumbnail({
      className: 'l-mb-025 l-mr-05',
      title: $.i18n(isOfficial ? "submission-view.attachments.filename" : "submission-view.attachments.title"),
      type: "success",
      filename: $.i18n("submission-view.attachments.filename"),
      href: "/services/datamodel/submittedAttachments/" + submission.id,
      icon: {
        props: {
          name: "attach",
          size: "large",
        }
      },
      "rg-container-id": "thumbnail-attachments"
    });
    const thuTaxCalc = Skeleton.elements.Thumbnail({
      className: 'l-mb-025 l-mr-05',
      title: $.i18n("submission-view.taxCalculation.title"),
      type: "success",
      filename: $.i18n("submission-view.taxCalculation.filename"),
      href: "/services/datamodel/submissionArtifact/" + taxCalculationArtifactId + "/" + submission.id,
      background: "img/"+this.taxYear+"/taxcalc.png",
      "rg-container-id": "thumbnail-taxcalc"
    });
    const unknown = $.i18n('commons-controls.no_input')
    const templateParams = {
      pidNumber: this.pidNumber,
      submissionDate: submission.timestamp ? fecha.format(submission.timestamp, 'DD.MM.YYYY') : unknown,
      submissionTime: submission.timestamp ? fecha.format(submission.timestamp, 'HH:mm') : unknown,
      expiryDate: expiryDate ? fecha.format(expiryDate, 'DD.MM.YYYY') : unknown,
      expiryTime: expiryDate ? fecha.format(expiryDate, 'HH:mm') : unknown,
      taxYear: this.taxYear,
      explanation: ''
    };

    const el = this.$el;
    requirejs(['text!templates/' + this.locale + (isOfficial ? '/submission-official.html' : '/submission.html')], function (template) {
      el.html(_.template(template)(templateParams));
      el.find(".thumbnails").append(thuDeclaration.toDom());
      if (!isOfficial) {
        el.find(".thumbnails").append(thuReceipt.toDom());
      }
      if (hasTaxCalc && isOfficial) {
        el.find(".thumbnails-dossier").append(thuTaxCalc.toDom());
      } else if (hasTaxCalc) {
        el.find(".thumbnails").append(thuTaxCalc.toDom());
      } else {
        el.find('.dossier').hide()
      }
      if (submission.hasAttachmentsPdf || submission.hasAttachmentsZip) {
        el.find(".thumbnails").append(thuAttachments.toDom());
      }
    });
    return this;
  }

});



export {SubmissionStateView};